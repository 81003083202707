import { Helmet } from "react-helmet";
import { page_titles } from "../PageTitles";
import { Link } from "react-router-dom";
import { Container, ContentSection, TitleAndDescription } from "../common-components/layout/PageLayoutParts";
import styled from "styled-components";
import { FAQs } from "../../utils/Constants";
import Accordion from "../common-components/Accordion";
import { getSlugFromString } from "../../utils/getSlugFromString";
import { useState, useEffect } from "react";

export function FAQ() {
    return (
        <>
            <Helmet>
                <title>{page_titles["faq"]}</title>
            </Helmet>

            <Container>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link className="breadcrumbs-link" to="/">
                                <i className="fa fa-home fa-xs"></i>
                            </Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            FAQ
                        </li>
                    </ol>
                </nav>

                <ContentSection>
                    <TitleAndDescription
                        title={"Frequently Asked Questions (FAQs)"}
                        description={"As the proud creators of several innovative financial tools that are unique in India, we acknowledge that users may experience a learning curve in utilizing our tools effectively. Here, you will find a compilation of the most frequently asked questions, based on our interactions with the community."}
                    />
                    <FaqQuestions />
                </ContentSection>
            </Container>
        </>
    );
}

const FaqQuestions = () => {
    const [openAccordionId, setOpenAccordionId] = useState(null);

    const handleQuestionClick = (id) => {
        setOpenAccordionId(openAccordionId === id ? null : id);
        updateUrl(id);
    };

    useEffect(() => {
        const hash = window.location.hash.substring(1);
        if (hash) {
            setOpenAccordionId(hash);
            const element = document.getElementById(hash);
            if (element) {
                var headerOffset = 100;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        }
    }, []);

    return (
        <Root>
            {FAQs.map((faqSection, sectionIndex) => (
                <FaqSection key={sectionIndex}>
                    <SectionTitle>{faqSection.section}</SectionTitle>
                    {faqSection.questions.map((faq, quesIndex) => {
                        const id = `${getSlugFromString(faqSection.section)}-${getSlugFromString(faq.question)}`;
                        return (
                            <Accordion
                                id={id}
                                question={faq.question}
                                answer={faq.answer}
                                key={quesIndex}
                                isOpen={openAccordionId === id}
                                onQuestionClick={handleQuestionClick}
                            />
                        );
                    })}
                </FaqSection>
            ))}
        </Root>
    );
}

const updateUrl = (id) => {
    const url = `${window.location.origin}${window.location.pathname}#${id}`;
    window.history.pushState({ path: url }, '', url);
}

const Root = styled.div``;

const FaqSection = styled.div`
    margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
    font-size: 1.3rem;
    font-weight: 300;
    margin-bottom: 35px;
`;

export default FAQ;
