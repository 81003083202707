import { createContext, useEffect, useState } from "react"
import DataFetchingDivCal from "./components/dividend-calendars/DataFetchingUpcoming";
import HomePage from "./components/homepage/HomePage";
import HistoricalDividendData from "./components/dividend-calendars/HistoricalDividendCalendar";
import Blog from "./components/blog/Blog";
import ContactUS from "./components/ContactForm";
import NewNoCostEMI from "./components/no-cost-emi-calculator/NewNoCostEMI";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NotFound404Page from "./components/not-found/NotFound404Page";
import DefaultLayout from "./components/common-components/layout/DefaultLayout";
import PrivacyPolicy from "./components/legal-docs/PrivacyPolicy";
import TermsAndConditions from "./components/legal-docs/TermsAndConditions"
import ExtensionPrivacyPolicy from "./components/legal-docs/ExtensionPrivacyPolicy"
import Login from "./components/user-authetication/Login";
import Signup from "./components/user-authetication/Signup";
import { ToastContainer } from "react-toastify";
import ThankYouPage from "./components/thank-you/ThankYouPage";
import OldNoCostEMI from "./components/no-cost-emi-calculator/OldNoCostEMI";
import { checkAmazonAffCookie } from "./utils/checkAffCookie";
import axios from "axios";
import { getAppConfig } from "./config/appConfig.js";
import BlogPost from "./components/blog/BlogPost.js";
import Spinner from "./components/Spinner.js";
import MyProfile from "./components/user-profile/MyProfile.js";
import { sendErrorLog } from "./utils/sendErrorLog.js";
import FAQ from "./components/faq/FAQ.js";
import { showToastNotification } from "./utils/ToastNotification.js";

export const AppContext = createContext();
const appConfig = getAppConfig();

function App() {
  const [user, setUser] = useState(null);
  const [isUserLoggedIn, setUserLoggedin] = useState(false);
  const [redirectedToAffiliate, setRedirectedToAffiliate] = useState(false);
  const [token, setToken] = useState('');
  const [fetchingToken, setFetchingToken] = useState(true);

  // Function to check if localStorage is accessible
  const checkLocalStorage = () => {
    try {
      const test = '__storage_test__';
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  };

  var userData = null;

  // Function to get user data from localStorage
  const getUserData = () => {
    try {
      userData = JSON.parse(localStorage.getItem("user"));
      return userData ? userData : null;
    } catch (error) {
      console.error("LocalStorage is not accessible:", error);
      return null;
    }
  };

  useEffect(() => {
    if (checkLocalStorage()) {
      const userData = getUserData();
      setUser(userData);
      setUserLoggedin(userData ? true : false);
    } else {
      showToastNotification("This application requires access to local storage to function properly. Please enable local storage.", "error", 'app-local-storage');
      console.error("LocalStorage is not accessible.");
      setUser(null);
      setUserLoggedin(false);
    }

    setRedirectedToAffiliate(checkAmazonAffCookie());
  }, []);

  const authRequestBody = {
    "username": appConfig.username,
    "password": appConfig.password
  }

  useEffect(() => {
    axios.post(appConfig.auth, authRequestBody).then(res => {
      const token = res.data.jwt;
      setToken(token);
      localStorage.setItem("token", token);
      setFetchingToken(false);
    }).catch(error => {
      setFetchingToken(false);
      sendErrorLog({
        errorContent: error,
        pageId: "App.js",
        user: user?.email,
        remarks: "Error while fetching token",
      });
    });
  }, [])

  if (fetchingToken) {
    return <div className="loader-wrapper"><Spinner /></div>
  }

  return (
    <>
      <AppContext.Provider
        value={{
          user,
          setUser,
          isUserLoggedIn,
          setUserLoggedin,
          redirectedToAffiliate,
          token
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<DefaultLayout />}>
              <Route path="/" element={<HomePage />} default />
              <Route path="/upcoming-dividend-calendar" element={<DataFetchingDivCal />} />
              <Route path="/historic-dividend-calendar" element={<HistoricalDividendData />} />
              <Route path="/contact-us" element={<ContactUS />} />
              <Route path="/no-cost-emi-calculator" element={<NewNoCostEMI />} />
              <Route path="/old-no-cost-emi-calculator" element={<OldNoCostEMI />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/privacy-policy/extension" element={<ExtensionPrivacyPolicy />} />
              <Route path="/blogs" element={<Blog />} />
              <Route path="/blogs/:slug" element={<BlogPost />} />
              <Route path="/contact" element={<Navigate to="/contact-us" />} />
              <Route path="/div-cal" element={<Navigate to="/upcoming-dividend-calendar" />} />
              <Route path="/his-div-dat" element={<Navigate to="/historic-dividend-calendar" />} />
              <Route path="/NCE-cal" element={<Navigate to="/no-cost-emi-calculator" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/my-profile" element={<MyProfile />} />
            </Route>
            <Route path="*" element={<NotFound404Page />} />
            <Route path="/thank-you" element={<ThankYouPage />} />
          </Routes>

        </BrowserRouter>
        <ToastContainer />
      </AppContext.Provider>
    </>
  );
}

export default App;
