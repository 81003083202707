import React, { useState } from 'react';
import styled from 'styled-components';

const Accordion = ({ question, answer, id, isOpen, onQuestionClick }) => {

    const handleQuestionClick = () => {
        onQuestionClick(id);
    };

    return (
        <AccordionItem id={id}>
            <Question onClick={handleQuestionClick}>
                <span dangerouslySetInnerHTML={{ __html: question }}></span>
                <ToggleIcon><i className={isOpen ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'} /></ToggleIcon>
            </Question>
            {isOpen && <Answer dangerouslySetInnerHTML={{ __html: answer }} />}
        </AccordionItem>
    );
};

export default Accordion;

const AccordionItem = styled.div`
    margin-bottom: 5px;
    padding: 1.5rem;
    background-color: var(--light-blue);
    color: black;
    border-radius: 13px;
`;

const Question = styled.div`
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ToggleIcon = styled.span`
    font-size: 1.5rem;
    
    &:hover {
        opacity: 0.8;
    }
`;

const Answer = styled.div`
    margin-top: 1rem;
    font-size: 1rem;
    li{
        padding-left: 20px;
    }
`;
