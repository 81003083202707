import { AMAZON_TODAYS_DEALS_AD, FLIPKART_AFFILIATE_AD } from './Constants';
import { checkAmazonAffCookie, checkFlipkartAffCookie } from './checkAffCookie';
import { setAmazonAffOpenedCookie, setFlipkartAffOpenedCookie } from './setAffOpenedCookie';

export const openAffiliateLinkIfValid = (urlVariables = {}, oldUrl) => {
    const amazonAffLinkOpened = checkAmazonAffCookie();
    const flipkartAffLinkOpened = checkFlipkartAffCookie();

    if (!amazonAffLinkOpened) {
        const amazonAffiliateLink = AMAZON_TODAYS_DEALS_AD;

        const hashParams = Object.keys(urlVariables).length > 0
            ? Object.entries(urlVariables).map(([key, value]) => `${key}=${value}`).join('&')
            : '';

        const currentUrl = oldUrl ? oldUrl : (hashParams
            ? `${window.location.origin}${window.location.pathname}#${hashParams}`
            : window.location.href);

        window.open(currentUrl, "_blank", "noopener noreferrer");
        window.location.href = amazonAffiliateLink;

        // SET COOKIE AFTER REDIRECTING TO AMAZON
        setAmazonAffOpenedCookie();
    }
    else {
        {/* Temporary stop on redirection to flipkart */}
        // if (!flipkartAffLinkOpened) {
        //     const flipkartAffiliateLink = FLIPKART_AFFILIATE_AD;

        //     const hashParams = Object.keys(urlVariables).length > 0
        //         ? Object.entries(urlVariables).map(([key, value]) => `${key}=${value}`).join('&')
        //         : '';

        //     const currentUrl = oldUrl ? oldUrl : (hashParams
        //         ? `${window.location.origin}${window.location.pathname}#${hashParams}`
        //         : window.location.href);

        //     window.open(currentUrl, "_blank", "noopener noreferrer");
        //     window.location.href = flipkartAffiliateLink;

        //     // SET COOKIE AFTER REDIRECTING TO AMAZON
        //     setFlipkartAffOpenedCookie();
        // }
    }
};
